<script setup lang="ts"></script>

<template>
  <div class="v1-landing-wrapper">
    <slot />
  </div>
</template>

<style scoped lang="postcss">
.v1-landing-wrapper {
  width: 100%;
  position: relative;
  padding-left: 24px;
  padding-right: 24px;
}

@media (--pl-viewport-from-l) {
  .v1-landing-wrapper {
    padding-left: calc(50% - 403px);
  }
}
</style>
